/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/extensions */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setCurrentScoreSheet, uploadScoreSheetFailure } from 'redux/modules/scoreSheets';

import { Label, Input, UploadWrapper } from './styled';

class UploadButton extends PureComponent {
  static defaultProps = {
    backgroundColor: '#03699A',
    textColor: '#fff',
    border: 'none',
    size: 'md',
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    border: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'md', 'mdIcon', 'sm']),
    actions: PropTypes.shape({
      setCurrentScoreSheet: PropTypes.func.isRequired,
      uploadScoreSheetFailure: PropTypes.func.isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.onFileChangeHandler = this.onFileChangeHandler.bind(this);
    this.state = {
      qrCode: null,
      scanning: false,
    };
  }

  onFileChangeHandler(e) {
    if (!this.state.qrCode) return;

    const currentFile = e.target.files[0];

    if (currentFile) {
      const imageUrl = URL.createObjectURL(currentFile);
      try {
        // QR code decoded case - https://api-env.kready.org/score-sheet/upload?studentEnrollmentId=x&dataCollectionId=y
        const queryParams = this.state.qrCode.split('?')[1];

        if (!this.state.qrCode.includes('kready.org') || !queryParams) {
          throw String('Invalid QR code');
        }

        const searchParams = new URLSearchParams(queryParams);
        const enrollmentId = searchParams.get('studentEnrollmentId');
        const collectionId = searchParams.get('dataCollectionId');

        // eslint-disable-next-line no-restricted-globals
        if (isNaN(collectionId) || isNaN(enrollmentId)) {
          throw String('Incorrect QR code found');
        } else {
          this.props.actions.setCurrentScoreSheet({
            file: currentFile,
            collectionId,
            enrollmentId,
            url: imageUrl,
          });
        }
      } catch (error) {
        // QR code not found case
        // QR code incorrect
        this.props.actions.setCurrentScoreSheet({
          file: null,
          collectionId: null,
          enrollmentId: null,
          url: imageUrl,
        });
        this.props.actions.uploadScoreSheetFailure(error);
      }
      this.setState({ scanning: false, qrCode: null });
      this.props.history.push('/scoresheets/preview');
    }
  }

  render() {
    const { buttonText, ...rest } = this.props;

    return (
      <div>
        <UploadWrapper>
          <Label
            {...rest}
            onClick={() => {
              if (this.state.scanning || this.state.qrCode) return;
              this.setState({ qrCode: null, scanning: true });
            }}
          >
            {!this.state.qrCode ? buttonText : 'Take Picture*'}
            {!!this.state.qrCode && (
              <Input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={this.onFileChangeHandler}
                aria-label="Upload photo"
              />
            )}
          </Label>
        </UploadWrapper>
        {this.state.scanning && (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              zIndex: 100,
              transform: 'translate(-50%, -50%)',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              padding: '0 16px 16px',
            }}
          >
            <h3>Scan QR Code</h3>
            <QrReader
              delay={100}
              onScan={(data) => {
                if (data) {
                  this.setState({ scanning: false, qrCode: data });
                }
              }}
              style={{ height: 240, width: 240 }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setCurrentScoreSheet, uploadScoreSheetFailure }, dispatch),
});

export default connect(null, mapDispatchToProps)(UploadButton);
