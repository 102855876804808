import http from '../../../http';
import getFileBase64 from '../../../services/getFileBase64';

const SET_CURRENT_SCORESHEET = 'rfc/scoreSheets/upload/SET_CURRENT_SCORESHEET';
const UPLOAD_SCORESHEET_REQUEST = 'rfc/scoreSheets/upload/UPLOAD_SCORESHEET_REQUEST';
const UPLOAD_SCORESHEET_SUCCESS = 'rfc/scoreSheets/upload/UPLOAD_SCORESHEET_SUCCESS';
const UPLOAD_SCORESHEET_FAILURE = 'rfc/scoreSheets/upload/UPLOAD_SCORESHEET_FAILURE';
const SCORESHEET_REFRESH_INITIAL = 'rfc/scoreSheets/upload/SCORESHEET_REFRESH_INITIAL';

const GET_SCORESHEETS_HISTORY_REQUEST = 'rfc/scoreSheets/history/GET_SCORESHEETS_HISTORY_REQUEST';
const GET_SCORESHEETS_HISTORY_SUCCESS = 'rfc/scoreSheets/history/GET_SCORESHEETS_HISTORY_SUCCESS';
const GET_SCORESHEETS_HISTORY_FAILURE = 'rfc/scoreSheets/history/GET_SCORESHEETS_HISTORY_FAILURE';
const HISTORY_REFRESH_INITIAL = 'rfc/scoreSheets/history/HISTORY_REFRESH_INITIAL';


const initialState = {
  imageUploading: false,
  uploadingError: '',
  currentImageUrl: '',
  currentCollectionId: null,
  currentEnrollmentId: null,
  file: null,

  historyLoading: false,
  historyError: '',
  historyData: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_SCORESHEET:
      return {
        ...state,
        currentImageUrl: action.payload.url,
        currentCollectionId: action.payload.collectionId,
        currentEnrollmentId: action.payload.enrollmentId,
        uploadingError: '',
        file: action.payload.file,
      };
    case UPLOAD_SCORESHEET_REQUEST:
      return { ...state, imageUploading: true };
    case UPLOAD_SCORESHEET_SUCCESS:
      return {
        ...state,
        uploadingError: '',
        imageUploading: false,
      };
    case UPLOAD_SCORESHEET_FAILURE:
      return {
        ...state,
        uploadingError: action.payload,
        imageUploading: false,
      };
    case SCORESHEET_REFRESH_INITIAL:
      return {
        ...state,
        imageUploading: false,
        uploadingError: '',
        currentImageUrl: '',
        currentCollectionId: null,
        currentEnrollmentId: null,
      };

    case GET_SCORESHEETS_HISTORY_REQUEST:
      return {
        ...state,
        historyLoading: true,
      };
    case GET_SCORESHEETS_HISTORY_SUCCESS:
      return {
        ...state,
        historyLoading: false,
        historyError: '',
        historyData: action.payload,
      };
    case GET_SCORESHEETS_HISTORY_FAILURE:
      return {
        ...state,
        historyLoading: false,
        historyError: action.payload,
      };
    case HISTORY_REFRESH_INITIAL:
      return {
        ...state,
        historyLoading: false,
        historyError: '',
        historyData: [],
      };
    default:
      return state;
  }
}

export function setCurrentScoreSheet(payload) {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_SCORESHEET, payload });
  };
}

export function scoreSheetRefreshInitial() {
  return (dispatch) => {
    dispatch({ type: SCORESHEET_REFRESH_INITIAL });
  };
}

const uploadScoreSheetSuccess = ({ history }) => {
  history.push('/collections/list');
  return ({ type: UPLOAD_SCORESHEET_SUCCESS });
};
const uploadScoreSheetRequestFailure = payload => ({ type: UPLOAD_SCORESHEET_FAILURE, payload });

export function uploadScoreSheetFailure(payload) {
  return (dispatch) => {
    dispatch({ type: UPLOAD_SCORESHEET_FAILURE, payload });
  };
}
export function uploadScoreSheet({
  collectionId,
  enrollmentId,
  history,
  file,
}) {
  return (dispatch) => {
    dispatch({ type: UPLOAD_SCORESHEET_REQUEST });
    fetch(file).then(r => r.blob()).then(blob => getFileBase64(blob)).then((fileBase64) => {
      http.post(
        `en/api/v1/user/collections/${collectionId}/enrollments/${enrollmentId}/scoresheets.json`,
        {
          fileName: 'score-sheet.png',
          fileData: fileBase64,
        },
      )
        .then(() => dispatch(uploadScoreSheetSuccess({ history })))
        .catch((error) => {
          dispatch(uploadScoreSheetRequestFailure(error.response.data || error.message || 'Something went wrong'));
        });
    });
  };
}

export function historyRefreshInitial() {
  return (dispatch) => {
    dispatch({ type: HISTORY_REFRESH_INITIAL });
  };
}

const getScoreSheetsHistorySuccess = () => ({ type: GET_SCORESHEETS_HISTORY_SUCCESS });
const getScoreSheetsHistoryFailure = payload => ({ type: GET_SCORESHEETS_HISTORY_FAILURE, payload });

export function getScoreSheetsHistoryRequest() {
  return (dispatch) => {
    dispatch({ type: GET_SCORESHEETS_HISTORY_REQUEST });
    http.get('get/history/api')
      .then(response => dispatch(getScoreSheetsHistorySuccess(response.data)))
      .catch(error => dispatch(getScoreSheetsHistoryFailure(error)));
  };
}
