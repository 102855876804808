import styled from 'styled-components';
import { sizes } from 'configs/btnConfigs';

export const UploadWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: 3px;
    color: ${({ textColor }) => textColor || 'white'};
    border: ${({ border }) => (border || 'none')};
    font-size: ${({ size, fontSize }) => (fontSize || sizes[size].fontSize)};
    font-weight: ${({ size, fontWeight }) => (fontWeight || sizes[size].fontWeight)};
    text-transform: ${({ textTransform = 'none' }) => (textTransform)};
    text-align: center;
    min-height: 40px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &:focus-within {
        outline: none;
        border: ${({ theme }) => `2px solid ${theme.primaryBlue}`};
    }

`;

export const Input = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;
