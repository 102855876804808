/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/extensions */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { scoreSheetRefreshInitial, uploadScoreSheet } from 'redux/modules/scoreSheets';

import theme from 'theme';

import Button from '../../components/ActionButton';
import UploadButton from '../../components/UploadButton';

import {
  Container,
  Wrapper,
  PageTitle,
  ImageWrapper,
  PreviewImage,
  Error,
  ActionPanel,
  ButtonWrapper,
} from './styled';


class ScoreSheetsPreview extends PureComponent {
    static defaultProps = {
      currentCollectionId: null,
      currentEnrollmentId: null,
      file: null,
    }

  static propTypes = {
    history: PropTypes.object.isRequired,
    uploadingError: PropTypes.string.isRequired,
    currentImageUrl: PropTypes.string.isRequired,
    actions: PropTypes.shape({
      scoreSheetRefreshInitial: PropTypes.func.isRequired,
      uploadScoreSheet: PropTypes.func.isRequired,
    }).isRequired,
    currentCollectionId: PropTypes.string,
    currentEnrollmentId: PropTypes.string,
    file: PropTypes.shape({}),
  }

  constructor(props) {
    super(props);

    this.onCancelHandler = this.onCancelHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onCancelHandler() {
    this.props.history.push('/collections/list');
    this.props.actions.scoreSheetRefreshInitial();
  }

  onSubmitHandler() {
    const {
      history,
      currentCollectionId,
      currentEnrollmentId,
      currentImageUrl,
    } = this.props;

    this.props.actions.uploadScoreSheet({
      collectionId: currentCollectionId,
      enrollmentId: currentEnrollmentId,
      history,
      file: currentImageUrl,
    });
  }

  render() {
    return (
      <Container role="main" aria-label="Score Sheets Preview">
        <Wrapper>
          <PageTitle>Preview</PageTitle>
          <ImageWrapper>
            <PreviewImage
              src={this.props.currentImageUrl}
              alt="Preview Image"
            />
          </ImageWrapper>
          <Error>{this.props.uploadingError}</Error>
          <ActionPanel>
            <ButtonWrapper>
              <Button
                onClick={this.onSubmitHandler}
                buttonText="Submit this image"
                disabled={!!this.props.uploadingError || !this.props.currentImageUrl}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <UploadButton
                buttonText="Take a new photo"
                history={this.props.history}
                textTransform="uppercase"
                backgroundColor={theme.primaryGreen}
                textColor={theme.primaryWhite}
                fontSize="14px"
                fontWeight={700}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                onClick={this.onCancelHandler}
                buttonText="Cancel"
              />
            </ButtonWrapper>
          </ActionPanel>
        </Wrapper>
      </Container>
    );
  }
}

const mapStateToProps = ({
  scoreSheets: {
    uploadingError,
    currentImageUrl,
    currentCollectionId,
    currentEnrollmentId,
    file,
  },
}) => ({
  uploadingError,
  currentImageUrl,
  currentCollectionId,
  currentEnrollmentId,
  file,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ scoreSheetRefreshInitial, uploadScoreSheet }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScoreSheetsPreview);
